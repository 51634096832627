<template>
  <div class="addCategory">
    <div class="container-fluid container-edit pt-5 pb-4">
      <h1 class="text-center title-mobile pb-2">{{$t('addCategoryTitle')}}</h1>
    </div>
    <form id="app" @submit.prevent="handleSubmit">
      <div class="container-fluid pt-5 pr-5 pl-5">
        <div class="row d-flex justify-content-center">
          <div class="col-xl-6 col-lg-12 col-xs-12 container-mobile-content">
            <div class="row">
              <div class="form-group col-6">
                <label for="nameFr">{{$t('categoryNameLabel')}} <span>*</span></label>
                <input type="text" v-model="category.name.fr" class="form-control" name="nameFr" id="nameFr" placeholder="nom" :class="{ 'is-invalid': submitted && $v.category.name.fr.$error }">
                <div v-if="submitted && !$v.category.name.fr.required" class="invalid-feedback">{{$t('fillFieldError')}}</div>
                <small class="form-text text-muted">{{$t('french')}}</small>
              </div>
              <div class="form-group col-6">
                <label for="nameEn">{{$t('categoryNameLabel')}} <span>*</span></label>
                <input type="text" v-model="category.name.en" class="form-control" name="nameEn" id="nameEn" placeholder="name" :class="{ 'is-invalid': submitted && $v.category.name.en.$error }">
                <div v-if="submitted && !$v.category.name.en.required" class="invalid-feedback">{{$t('fillFieldError')}}</div>
                <small class="form-text text-muted">{{$t('english')}}</small>
              </div>
            </div>
            <div class="form-group">
              <label for="sorting">{{$t('categorySortLabel')}}</label>
              <input type="number" v-model="category.sorting" class="form-control" name="sorting" min="1" max="999" id="sorting" placeholder="999">
              <small class="form-text text-muted">{{$t('categorySortExplain')}}</small>
            </div>
            <div class="form-group">
              <label for="parentCategory">{{$t('categoryParentLabel')}} <span>*</span></label>
              <b-form-input list="list-cat" v-model="category.parentCategory" id="parentCategory" :placeholder="$t('categoryParentLabelHolder')" :class="{ 'is-invalid': needCat }"></b-form-input>
              <b-form-datalist id="list-cat" :options="categories" text-field="name" value-field="name"></b-form-datalist>
              <div v-if="needCat" class="invalid-feedback">{{$t('categoryParentLabelHolder')}}</div>
            </div>
            <div class="row d-flex justify-content-center custom-control custom-switch" for="visible"> 
              <input type="checkbox" v-model="category.visible" class="custom-control-input" name="visible" id="visible">
              <label class="custom-control-label" for="visible">{{$t('visible')}}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid container-mobile-content">
        <div class="row col-12 pt-5 pl-5 d-flex justify-content-center align-items-center">
          <div class="form-group">
            <small class="form-text text-muted pl-4 pb-1">{{$t('categoryImageSizeInfo')}}</small>
            <input type="file" class="input-file" @change="onFileSelected" name="image" id="image">
            <p class="file-label">{{$t('insertImage')}} <i class="fas fa-image ml-2"></i></p>
          </div>
          <div class="pb-2">
            <img class="round" width="130px" height="auto" :src="''">
          </div>
        </div>
      </div>
      <div class="container-fluid pr-5 pl-5">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-2 pt-1 d-flex justify-content-center">
            <div class="form-group">
              <button type="submit" class="btn btn-primary pr-3">{{$t('send')}} <i class="fas fa-pencil-alt ml-2"></i></button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- <Setting :location="'category/add'" /> -->
  </div>
</template>

<script>

var utils = require('@/assets/js/utils');
import { required } from "vuelidate/lib/validators";
import Setting from '@/components/Settings.vue';

export default {
  name : 'AddCategory',
  components:{
    Setting,
  },
  data() {
    return {
      category: {
        name: {fr: '', en: ''},
        image: null,
        parentCategory: undefined,
        sorting: null,
        visible: true,
        imageBase64: null,
      },
      categories: [],
      submitted: false,
      needCat: false,
      url: utils.getConfig().URL,
    }
  },
  validations: {
    category: {
      name: { fr: {required}, en: {required} },
    }
  },
  methods: {
    getID(name){
      var cat = this.categories.find(c => c.name == name)
      if(cat != undefined){
        return cat.id;
      }
      else{ return null; }
    },
    onFileSelected(event){
      let result;
      this.category.image = event.target.files[0]

      var reader = new FileReader();
      reader.readAsDataURL(this.category.image);
      reader.onload =  (e) => {
        result = e.target.result;
        $(".round").attr("src", result);
        $(".round").css("margin-left", "1rem");
        this.category.imageBase64 = result.split(',')[1];
      };
    },
    handleSubmit(e){
      this.submitted = true;

      if(this.category.parentCategory != undefined){
        var parentCategory = this.getID(this.category.parentCategory);

        if(parentCategory == null){
          this.needCat = true;
          this.category.parentCategory = undefined;
          return;
        }
        else{ this.needCat = false; }
      }
      else{ var parentCategory = null; }

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      else{
        var dataToInsert = { 
          name:[
            {lang: 'fr', value: this.category.name.fr},
            {lang: 'en', value: this.category.name.en},
          ],
          is_visible : this.category.visible,
          parent_category: parentCategory
        }

        if(this.category.imageBase64 != null){
          dataToInsert.imageBase64 = this.category.imageBase64;
        }
        if(this.category.sorting != null){
          dataToInsert.sorting = this.category.sorting
        }

        const formData = new FormData();
        formData.append('params', JSON.stringify(dataToInsert));

        let loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          backgroundColor: '#000000',
          color: '#fff',
          opacity: 0.7,
          width: 100,
          height: 100,
        });

        this.$http.post(this.url + 'catalog/category/save', formData)
        .then(function(response){
          loader.hide();
          window.location = "/success";
        })
        .catch(function (e){
          console.log(e);
        })
      }
    },
  },
  mounted(){
    this.$http.get(utils.getConfig().URL + 'catalog/category/get/all/formated')
    .then((response) => {
      this.categories = response.data;
    });
  }
}
</script>


